#shop-info {

  section.mainvisual {
    height: 102px;
    text-align: center;
    background-image: url("../../../img/img_bg_main_2.png");
    background-position-x: center;
    background-position-y: 65%;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    flex-wrap: wrap;

    .main-title {
      font-size: 23px;
      font-family: 'ヒラギノ丸ゴ ProN','Hiragino Maru Gothic ProN';
      font-weight: bold;
      //line-height: 1.8rem;
      color: #333739;
      width: 100%;

      img {
        height: 30px;
      }

    }

    .sub-title {
      width: 100%;
      font-size: 12px;
      font-family: 'ヒラギノ丸ゴ ProN','Hiragino Maru Gothic ProN';
      font-weight: bold;
      color: #B83900;
    }

  }

  .store-intro {

    .title-wrap {
      width: 90%;
      margin: auto;
      padding: 30px 0 20px;
      text-align: center;

      .title {
        font-size: 20px;
        font-family: 'ヒラギノ丸ゴ ProN','Hiragino Maru Gothic ProN';
        font-weight: bold;
        letter-spacing: .2rem;
        line-height: 1.8rem;
        color: #B83900;
      }

      .category {
        margin-top: 15px;
        display: flex;
        justify-content: center;
        flex-wrap: wrap;

        .category-item {
          font-size: 12px;
          font-family: 'ヒラギノ丸ゴ ProN','Hiragino Maru Gothic ProN';
          font-weight: bold;
          letter-spacing: .2rem;
          color: #333739;
          margin: 10px;
        }

      }

    }

    .content-wrap {
      padding: 0 0 30px;
      .text-area {
        width: 90%;
        margin: 0 auto;
        font-size: 12px;
        font-family: 'ヒラギノ丸ゴ ProN','Hiragino Maru Gothic ProN';
        line-height: 1.4rem;
        letter-spacing: 1.92px;
        color: #333739;
        margin-bottom: 40px;
      }

      .product-area {
        width: 90%;
        margin: auto auto 40px;

        ul {
          display: flex;
          justify-content: space-around;

          li {
            width: 48%;

            .item-img {
              width: 100%;
              height: 142px;
              background-repeat: no-repeat;
              background-position: center;
              background-size: cover;
            }

            .item-text {
              margin-top: 10px;

              .item-title {
                font-size: 15px;
                font-family: 'ヒラギノ丸ゴ ProN','Hiragino Maru Gothic ProN';
                font-weight: bold;
                line-height: 1.6rem;
                letter-spacing: 1.6px;
                color: #B83900;
                margin-bottom: 10px;
              }

              .item-price {
                font-size: 13px;
                font-family: 'ヒラギノ丸ゴ ProN','Hiragino Maru Gothic ProN';
                font-weight: bold;
                letter-spacing: 1.92px;
                color: #333739;
              }

            }

          }

        }

      }

    }

  }

  .product-intro,
  .product-intro-sub{
    width: 100%;
    background-color: rgba(255, 255, 255, 0.5);
    margin: 0 auto;
    padding-bottom: 30px;

    .title-wrap {
      text-align: center;
      width: 80%;
      margin: auto auto;
      padding: 30px 0 40px;
    }

    .content-wrap {
      width: 90%;
      margin: auto;
      ul {
        width: 100%;
        margin: 0 auto;

        .list-item {
          width: 100%;
          margin-bottom: 50px;
          position: relative;

          .item-img {
            width: 100%;
            height: 226px;
            margin-bottom: 15px;
            overflow: hidden;

            .img-wrap {
              position: relative;
              width: 100%;
              height: 226px;
              overflow: hidden;

              img {
                position: relative;
                max-width: 100%;
                max-height: 100%;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
              }

            }

          }

          .item-title {
            margin-top: 10px;
            font-size: 18px;
            font-family: 'ヒラギノ丸ゴ ProN','Hiragino Maru Gothic ProN';
            font-weight: bold;
            letter-spacing: 3.84px;
            color: #B83900;
            text-align: center;
          }

          .item-text {
            margin-top: 20px;
            font-size: 13px;
            font-family: 'ヒラギノ丸ゴ ProN','Hiragino Maru Gothic ProN';
            line-height: 1.6rem;
            letter-spacing: .2rem;
            color: #333739;
          }

        }

      }

      .product-text {
        width: 100%;
        padding: 10px 0;
        font-size: 13px;
        letter-spacing: .2rem;
        line-height: 1.6rem;
      }

      .contact-status {
        position: relative;
        bottom: 0;
        width: 100%;
        margin-top: 40px;

        li {
          margin-bottom: 20px;

          a {
            text-decoration: none;
            display: block;
            width: 90%;
            margin: 0 auto;

            .and-more {
              height: 60px;
              border-radius: 50px;
              display: flex;
              align-items: center;
              justify-content: center;

              .link-text {
                display: flex;
                justify-content: center;
                align-items: center;

                .text {
                  font-size: 13px;
                  font-family: 'ヒラギノ丸ゴ ProN','Hiragino Maru Gothic ProN';
                  line-height: 23px;
                  font-weight: bold;
                  letter-spacing: 3.6px;
                  color: #FFFFFF;
                }

                .arrow {
                  margin-left: 20px;
                  width: 0;
                  height: 0;
                  border-style: solid;
                  border-width: 7.5px 0 7.5px 11px;
                  border-color: transparent transparent transparent #fff;
                }

              }

            }

            &.sp-none {
              display: none;
            }

          }

        }

        .shop {

          a {

            .and-more {
              background-color: #B8A607;

              .link-text {

                .arrow {
                  margin-left: 15px;
                }

              }

            }

          }

        }

        .tel {

          a {

            .and-more {
              background-color: #333739;

              .link-text {

                .arrow {
                  margin-left: 25px;
                }

              }

            }

          }

        }

      }

    }

  }

  .product-intro-sub {
    margin-top: 65px;
  }

  .store-info {
    width: 100%;
    padding: 20px 0 30px;

    .title-wrap {
      text-align: center;
      width: 80%;
      margin: auto;
      padding: 0 0 25px;
    }

    .content-wrap {
      width: 90%;
      margin: auto;


      .map-area {
        width: 100% !important;
        height: 160px !important;
        margin-bottom: 20px;
        iframe {
          height: 160px;
        }
      }

      .info-area {
        margin-top: -10px;
        width: 100%;

        .store-name {
          font-size: 22px;
          font-family: 'ヒラギノ丸ゴ ProN','Hiragino Maru Gothic ProN';
          line-height: 50px;
          letter-spacing: 3.84px;
          color: #B83900;
          border-bottom: solid #B83900 2px;
        }

        .info-list {
          margin: 15px 0;

          .list-item {
            display: flex;
            justify-content: flex-start;
            align-items: center;

            .list-title {
              width: 20%;
              margin-right: 15px;
              font: 13px Kozuka Gothic Pr6N;
              font-weight: bold;
              color: #333739;
            }

            .list-content {
              width: 70%;
              font: 13px Kozuka Gothic Pr6N;
              color: #333739;
            }

          }

        }

        .link-area {

          a {
            text-decoration: none;

            .and-more {
              width: 90%;
              height: 60px;
              border-radius: 50px;
              display: flex;
              align-items: center;
              justify-content: center;
              margin: 0 auto;
              background-color: #B8A607;

              .link-text {
                display: flex;
                justify-content: center;
                align-items: center;

                .text {
                  font-size: 13px;
                  font-family: 'ヒラギノ丸ゴ ProN','Hiragino Maru Gothic ProN';
                  font-weight: bold;
                  letter-spacing: 3.6px;
                  color: #FFFFFF;
                }

                .arrow {
                  margin-left: 20px;
                  width: 0;
                  height: 0;
                  border-style: solid;
                  border-width: 7.5px 0 7.5px 11px;
                  border-color: transparent transparent transparent #fff;
                }

              }

            }

          }

        }

      }

    }

  }

  .more-shop {
    width: 100%;
    background-color: rgba(184, 166, 7, 0.1);

    .title-wrap {
      text-align: center;
      padding: 30px 0 40px;

      h2 {
        width: 80%;
        text-align: center;
        margin: auto;
      }
    }

    .content-wrap {
      width: 90%;
      margin: auto;

      ul {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;

        .list-item {
          width: 48%;
          margin: 0 0 15px;

          a {
            text-decoration: none;

            .item-img {
              width: 100%;
              height: 142px;
              background-repeat: no-repeat;
            }

            .item-text {
              margin-top: 10px;

              .item-title {
                font-size: 13px;
                font-family: 'ヒラギノ丸ゴ ProN','Hiragino Maru Gothic ProN';
                font-weight: bold;
                line-height: 23px;
                letter-spacing: 2.4px;
                color: #B83900;
              }

            }

          }

        }

      }

      .link-area {
        margin-top: 40px;
        padding-bottom: 40px;

        a {
          text-decoration: none;

          .and-more {
            width: 90%;
            height: 60px;
            border-radius: 50px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 0 auto;
            background-color: #B8A607;

            .link-text {
              display: flex;
              justify-content: center;
              align-items: center;

              .text {
                font-size: 13px;
                font-family: 'ヒラギノ丸ゴ ProN','Hiragino Maru Gothic ProN';
                font-weight: bold;
                letter-spacing: 3.6px;
                color: #FFFFFF;
              }

              .arrow {
                margin-left: 20px;
                width: 0;
                height: 0;
                border-style: solid;
                border-width: 7.5px 0 7.5px 11px;
                border-color: transparent transparent transparent #fff;
              }

            }

          }

        }

      }

    }

  }

  .shop-search {
    padding: 30px 0 40px;

    .title-wrap {
      margin-bottom: 30px;
      text-align: center;
      h2 {
        width: 90%;
        text-align: center;
        margin: auto;
      }
    }

    .content-wrap {
      width: 90%;
      margin: auto;

      .title-wrap {
        text-align: center;
        width: 90%;
        margin: auto auto 20px;
      }

      .area-search {
        width: 90%;
        margin: auto;
        ul {
          display: flex;
          justify-content: space-between;
          flex-wrap: wrap;
          width: 100%;
          margin: 0 auto;

          .list-item {
            width: 48%;
            height: auto;
            border: 2px solid #333739;
            background-color: #FFF;
            margin-bottom: 15px;
            text-align: center;
            padding: 20px 0;

            a {
              text-decoration: none;
              .item-wrap {
                width: 100%;
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                align-content: center;
                flex-wrap: wrap;

                .item-text {
                  img {
                    height: 30px;
                  }
                }

                .font-min {
                  width: 100%;
                  font-size: 11px;
                  font-family: 'ヒラギノ丸ゴ ProN','Hiragino Maru Gothic ProN';
                  font-weight: bold;
                }

                .item-img {
                  width: 100%;
                  text-align: center;
                }

              }

            }

          }

          .big-item {
            width: 100%;
          }

        }

      }

      .category-search {
        margin-top: 40px;

        .title-wrap {
          margin-bottom: 25px;
        }

        .content-wrap {

          ul {
            display: flex;
            justify-content: space-around;
            flex-wrap: wrap;
            zoom: .7;
            .list-item {
              width: 165px;
              margin: 0 0 20px;

              .circle {
                width: 165px;
                height: 165px;
                background-color: #FFF;
                border: 2px solid #333739;
                border-radius: 50%;
                display: flex;
                justify-content: center;
                align-items: center;
              }

              .item-text {
                margin-top: 20px;
                text-align: center;
                font: 18px GenSenMaruGothic JP TTF;
                line-height: 20px;
                font-weight: bold;
                letter-spacing: 2.6px;
                color: #333739;

                .sub-text {
                  margin-top: 10px;
                  font: 16px GenSenMaruGothic JP TTF;
                  line-height: 20px;
                  letter-spacing: 2.88px;
                }

              }

            }
          }

        }

      }

    }

  }


  .contact {
    background-color: rgba(184, 166, 7, 0.1);
    padding: 30px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;

    .contact-item {
      width: 90%;
      padding: 15px 0;
      margin: auto;

      a {
        text-decoration: none;

        .item-wrap {
          width: 100%;
          height: 100%;

          .item-img {
            text-align: center;
          }

          .item-text {
            margin-top: 25px;
            text-align: center;
            font: 14px GenSenMaruGothic JP TTF;
            font-weight: bold;
            line-height: 24px;
            letter-spacing: 4.32px;
          }

        }

      }

    }

    .app {
      background-color: #B8A607;

      .item-wrap {
        display: flex;
        justify-content: center;
        align-items: center;
        align-content: center;
        .item-img {
          text-align: center;
          margin-right: 20px;
          img {
            zoom: .6;
          }
        }

        .item-text {
          color: #FFF;
        }

      }

    }

    .cont {
      background-color: #FFF;
      border: 1px solid #707070;

      .item-wrap {
        display: flex;
        justify-content: center;
        align-items: center;
        align-content: center;
        .item-img {
          text-align: center;
          margin-right: 20px;
          img {
            zoom: .6;
          }
        }

        .item-text {
          color: #333739;;
        }

      }

    }

  }

}