body {
  background: transparent url('../../../img/img_bg_paper-800.jpg') 0% 0%  padding-box;
}

.pc-none {
  display: none;
}

header {

.global-navi {
  position: relative;
  z-index: 100;
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  height: 70px;
  background-color: #fff;

ul {
  padding-top: 30px;
  display:flex;
  justify-content: flex-end;

li {
  margin-right: 55px;

a {
  text-decoration: none;

.item-wrap {
  display: flex;
  align-items: flex-start;

.item-text {
  font: 18px GenSenMaruGothic JP TTF;
  line-height: 18px;
  font-weight: bold;
  letter-spacing: 3.24px;
  color: #333739;
}

.shop {
  margin-top: -3px;
  margin-right: 5px;
}


.search {
  margin-top: -6px;
  margin-right: 10px;
}

.form {
  margin-top: -11px;
  margin-right: 10px;
}

.mail {
  margin-top: -2px;
  margin-right: 10px;
}

}

}

}

li:nth-child(4) {
  margin-right: 20px;
}

}
}

.navi-wrap {
  width: 100%;
  height: 100%;
  background-color: rgba(184,166,7,0.5);
  background-image: url('../../../img/img_bg_paper-800.jpg');
}


}



.top-logo {
  position: fixed;
  top: 5px;
  left: 30px;
  z-index: 200;
}

footer {

  .footer-img {
    margin-top: 60px;
    text-align: center;
  }

  .info {
    text-align: center;
    margin-top: 70px;
    margin-bottom: 40px;

    .info-address,
    .info-contact {
      font: 14px Kozuka Gothic Pr6N;
      line-height: 30px;
      letter-spacing: 1.68px;
      color: #333739;
    }

    .info-contact {
      margin-top: 30px;
    }

  }

  .copyright {
    height: 100px;
    background: #B83900 0% 0% no-repeat padding-box;
    font: 12px Kozuka Gothic Pr6N;
    letter-spacing: 1.44px;
    color: #FFFFFF;
    display: flex;
    justify-content: center;
    align-items: center;
  }

}
