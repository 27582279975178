#form {

  section.mainvisual {
    height: 310px;
    text-align: center;
    background-image: url("../../../img/img_bg_main_2.png");
    background-position-x: center;
    background-position-y: 65%;
    background-repeat: no-repeat;
    background-size: cover;

    .main-title {
      padding-top: 155px;
      font: 38px GenSenMaruGothic JP TTF;
      font-weight: bold;
      letter-spacing: 6.84px;
      color: #333739;

      img {
        width: 262px;
      }

    }

    .sub-title {
      font: 12px GenSenMaruGothic JP TTF;
      font-weight: bold;
      letter-spacing: 2.16px;
      color: #B83900;
    }

  }

  section.form {

      .inner {
        padding: 80px 0 200px;
        margin: 0 auto;
        width: 1200px;

        .title-wrap {
          background-color: rgba(255, 255, 255, 0.5);
          text-align: center;
          margin: 0 auto;
          padding: 60px 0;

          .title {
            font: 28px GenSenMaruGothic JP TTF;
            color: #B83900;
            font-weight: bold;
          }

        }

        .content-wrap {

          form {

            .main-area {
              padding-bottom: 70px;
            }

            .sub-area {
              margin-top: 50px;
              padding-bottom: 60px;

              .title-wrap {
                background: none;

                p {
                  justify-content: center;

                  .sub-text {
                    display: block;
                    margin-top: 15px;
                  }

                }

              }

            }

            .main-area,
            .sub-area{
              background-color: rgba(255, 255, 255, 0.5);

              p {
                position: relative;
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                margin-bottom: 20px;

                #zip-btn {
                  width: 160px;
                  height: 50px;
                  margin-left: 24px;
                  background-color: #333739;
                  color: #fff;
                  font: 14px GenSenMaruGothic JP TTF;
                  font-weight: bold;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  border-radius: 5px;

                  .arrow {
                    margin-left: 20px;
                    width: 0;
                    height: 0;
                    border-style: solid;
                    border-width: 7.5px 0 7.5px 11px;
                    border-color: transparent transparent transparent #fff;
                  }

                }

                .mark {
                  margin-right: 7px;
                }

                .time-text {
                  margin: 0 20px;
                }


              }

              .textarea-wrap {
                align-items: flex-start;
                .label-195 {
                  margin-top: 15px;
                }
              }

              .pay-method-top {
                padding-top:15px;
              }

              .pay-method-wrap {
                align-items: flex-start;

                .wpcf7-list-item-label {
                  margin: 0 22px 0 7px;
                }

              }

              input[type="text"],
              input[type="tel"],
              input[type="email"],
              select{
                height: 50px;
                border: 1px solid #707070;
                padding: 0 20px;
                font: 14px Kozuka Gothic Pr6N;
              }

              input[type="checkbox"] {
                width: 20px;
                height: 20px;
                /*appearance: none;*/
                vertical-align: middle;
                border: 1px solid #707070;
                border-radius: 3px;
                background-color: #fff;
              }

              textarea {
                width: 695px;
                height: 105px;
                padding: 10px 20px;
                font: 14px Kozuka Gothic Pr6N;
              }

              .textarea-155 {
                height: 155px;
              }

              .holiday-wrap {

                .wpcf7-list-item-label {
                  font: 16px GenSenMaruGothic JP TTF;
                  font-weight: bold;
                  color: #333739;
                  margin: 0 16px 0 5px;
                }

              }

              select {/* selectのスタイルを無効化 */
                -webkit-appearance: none;
                -moz-appearance: none;
                appearance: none;
                border-radius: unset;
                background-color: #fff;
                border: 1px solid #707070;
              }

              .time-wrap {
                display: flex;
                align-items: center;
              }

              .select-wrap {
                position: relative;
                display: inline-block;
              }

              .select-wrap:after {
                content: '';
                position: absolute;
                width: 0;
                height: 0;
                border-style: solid;
                border-width: 8px 5.5px 0 5.5px;
                border-color: #333739 transparent transparent transparent;
                top: 23px;
                right: 22px;
              }


              .label-195 {
                width: 195px;
                display: inline-flex;
                justify-content: space-between;
                align-items: center;
                margin-left: 140px;
                margin-right: 35px;
                color: #333739;
                font: 18px GenSenMaruGothic JP TTF;
                font-weight: bold;
              }

              .mark-label {
                margin-right: 12px;
              }

              .holiday-label {
                margin-top: 15px;
              }

              .text-left {
                justify-content: flex-end;
              }

              .text-695 {
                width: 695px;
              }

              .text-630 {
                width: 630px;
              }

              .text-595 {
                width: 595px;
              }

              .text-580 {
                width: 580px;
              }

              .text-395 {
                width: 395px;
              }

              .text-360 {
                width: 360px;
              }

              .text-300 {
                width: 300px;
              }

              .text-210 {
                width: 210px;
              }

              .select-200 {
                width: 200px;
              }

              span.required {
                font-size: 11px;
                width: 57px;
                height: 28px;
                display: flex;
                justify-content: center;
                align-items: center;
                padding: 0;
                color: #fff;
                border-radius: 3px;
                background-color: #D04829;
                border-radius: 14px;
              }

              .input-required {
                margin: 0 16px 0 50px;
              }

              .note {
                font: 16px GenSenMaruGothic JP TTF;
                font-weight: bold;
                color: #333739;
                margin-right: 30px;
              }

              .checkbox-wrap {
                margin-left: 60px;
                width: 430px;
              }

              .sns-name {
                width: 115px;
                font: 16px GenSenMaruGothic JP TTF;
                font-weight: bold;
                color: #333739;
              }

            }

            .item-label {
              margin-left: 10px;
            }

            .image-method-wrap {

              input {
                vertical-align: text-bottom;
              }
              .wpcf7-list-item {
                margin: 0 50px 0 10px;
              }
            }

            .image-method-last {

              input {
                vertical-align: text-bottom;
              }
              .wpcf7-list-item {
                margin: 0 25px 0 10px;
              }

            }

            .method-name.last {
              margin: 0 25px 0 10px;
            }

            .image-method-wrap {

              .label-195 {
                position: relative;

                .sub-text {
                  position: absolute;
                  font: 14px GenSenMaruGothic JP TTF;
                  font-weight: bold;
                  color: #333739;
                  top: 25px;
                  left: 4px;
                }

              }

            }

            .sns-wrap {
              padding-top: 15px;
            }

            .image-method-note {
              font: 14px Kozuka Gothic Pr6N;

              span.red {
                color: #D04829;
                border-bottom: 1px solid #D04829;
              }

            }

            .submit-wrap {
              text-align: center;
              margin-top: 60px;

              .checklist {
                font: 16px GenSenMaruGothic JP TTF;
                line-height: 150%;
                width: 600px;
                margin: 0 auto;
                padding-bottom: 40px;
                text-align: left;

                span {
                  font: 18px GenSenMaruGothic JP TTF;
                  font-weight: bold;
                }
              }

              input[type="checkbox"] {
                width: 20px;
                height: 20px;
                /*appearance: none;*/
                vertical-align: middle;
                border: 1px solid #707070;
                border-radius: 3px;
                background-color: #fff;
              }

              .concent-note {
                display: inline-block;
                font: 16px GenSenMaruGothic JP TTF;
                font-weight: bold;
                color: #333739;
              }

              .confirm-wrap {
                width: 460px;
                margin: 20px auto 0;
                position: relative;

                input[type="submit"] {
                  width: 460px;
                  height: 90px;
                  border-radius: 50px;
                  display: inline-flex;
                  align-items: center;
                  justify-content: center;
                  font: 26px GenSenMaruGothic JP TTF;
                  font-weight: bold;
                  color: #fff;
                  background-color: #B8A607;
                }

                span.arrow {
                  position: absolute;
                  margin-left: 20px;
                  width: 0;
                  height: 0;
                  border-style: solid;
                  border-width: 7.5px 0 7.5px 11px;
                  border-color: transparent transparent transparent #fff;
                  top: 36px;
                  right: 150px;
                }

              }

            }

          }

        }

      }

  }

  .wpcf7-not-valid-tip {
    padding: .4em .8em;
    top: -26%;
    left: 10px;
    width: 250px;
  }

}
