body {
  background: transparent url('../../../img/img_bg_paper.png') 0% 0%  padding-box;
  position: relative;
}

/* 点滅 */
.blinking{
  -webkit-animation:blink 0.85s ease-in-out infinite alternate;
  -moz-animation:blink 0.85s ease-in-out infinite alternate;
  animation:blink 0.85s ease-in-out infinite alternate;
}
@-webkit-keyframes blink{
  0% {opacity:0;}
  100% {opacity:1;}
}
@-moz-keyframes blink{
  0% {opacity:0;}
  100% {opacity:1;}
}
@keyframes blink{
  0% {opacity:0;}
  100% {opacity:1;}
}


.sp-none {
  display: none !important;
}


img {
  max-width: 100%;
}

iframe {
  max-width: 100%;
}

header {
  position: fixed;
  z-index: 100;
  bottom: 0;
  left: 0;
  width: 100%;
  .global-navi {
    width: 100%;
    height: 55px;
    background-color: #fff;

    ul {
      display:flex;
      align-items: center;
      justify-content: space-between;
      padding: 10px 20px;
      height: 55px;

      li {
        a {
          text-decoration: none;

          .item-wrap {
            display: flex;
            align-items: center;
            justify-content: space-between;
            flex-wrap: wrap;

            .item-text {
              font:9px GenSenMaruGothic JP TTF;
              line-height: 9px;
              font-weight: bold;
              color: #333739;
              width: 100%;
              text-align: center;
            }

            .shop {
              width: 100%;
              text-align: center;
              margin-bottom: 5px;
              img {
                width: auto;
                height: 20px;
              }
            }

            .search {
              width: 100%;
              text-align: center;
              margin-bottom: 5px;
              img {
                width: auto;
                height: 20px;
              }
            }

            .form {
              width: 100%;
              text-align: center;
              margin-bottom: 5px;
              img {
                width: auto;
                height: 20px;
              }
            }

            .mail {
              width: 100%;
              text-align: center;
              margin-bottom: 5px;
              img {
                width: auto;
                height: 20px;
              }
            }

          }

        }

      }


    }
  }

  .navi-wrap {
    width: 100%;
    height: 100%;
    background-color: rgba(184,166,7,0.5);
    background-image: url('../../../img/img_bg_paper.png');
  }


}



.top-logo {
  position: fixed;
  top: 5px;
  left: 10px;
  z-index: 200;
  img {
    zoom: .5;
  }
}

footer {
  padding: 60px 0;
  .footer-img {
    text-align: center;
    img {
      zoom: .5;
    }
  }

  .info {
    width: 90%;
    margin: 20px 0 20px;
    text-align: center;

    .info-address,
    .info-contact {
      font: 12px Kozuka Gothic Pr6N;
      line-height: 1.4rem;
      color: #333739;
    }

    .info-contact {
      margin-top: 20px;
      a {
        color: #000;
        text-decoration: underline;
      }
    }

  }

  .copyright {
    height: 100px;
    background: #B83900 0% 0% no-repeat padding-box;
    font: 12px Kozuka Gothic Pr6N;
    letter-spacing: 1.44px;
    color: #FFFFFF;
    display: flex;
    justify-content: center;
    align-items: center;
  }

}
