#search {

  section.main-visual {
    height: 102px;
    text-align: center;
    background: url("../../../img/img_bg_main_2.png") no-repeat;
    background-position-x: center;
    background-position-y: 65%;
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    flex-wrap: wrap;

    .main-title {
      font: 23px GenSenMaruGothic JP TTF;
      font-weight: bold;
      color: #333739;
      width: 100%;

      img {
        height: 30px;
      }

    }

    .sub-title {
      width: 100%;
      font: 12px GenSenMaruGothic JP TTF;
      font-weight: bold;
      color: #b83900;
    }

  }

  section#free-word-search {

    .inner {
      width: calc(100% - 20px);
      margin: 0 auto;
      padding: 18px 0 55px;

      .title-wrap {
        background-color: rgba(255, 255, 255, 0.5);
        text-align: center;
        margin: 0 auto;
        padding: 30px 0 25px;

        .title {
          font: 18px GenSenMaruGothic JP TTF;
          font-weight: bold;
          letter-spacing: 3px;
          color: #b83900;
        }
      }

      .wrap {
        width: 100%;
        margin: 0 auto;
        background-color: rgba(255, 255, 255, 0.5);

        .box {
          width: 100%;
          margin: 0 auto;
          display: flex;
          justify-content: space-between;
          flex-wrap: wrap;

          .selectBox {
            width: 100%;
            margin: 0 15px 10px;
            position: relative;

            select {
              width: 100%;
              height: 44px;
              border: 1px solid #707070;
              background: #fff;
              padding: 10px 10px 10px 40px;
              font: 13px GenSenMaruGothic JP TTF;
              font-weight: bold;
              letter-spacing: 1px;
              color: #333739;
            }

            &:before {
              height: 21px;
              position: absolute;
              top: 10px;
              left: 10px;
            }
          }

          .area {
            &:before {
              content: url("../../../img/pc/search/icon_area.svg");
            }
          }

          .category {
            &:before {
              content: url("../../../img/pc/search/icon_cup.svg");
            }
          }

          .input-free {
            width: 100%;
            height: 44px;
            margin: 0 15px;
            padding: 10px;
            border: 1px solid #707070;
            background: #fff;
            font-size: 14px;
          }
        }

        .btn-area {
          padding: 20px 0 27px;
          display: flex;
          justify-content: center;

          .btn {
            cursor: pointer;
            display: block;
            width: 270px;
            height: 60px;
            border: none;
            background: url("../../../img/sp/search/btn_search.svg") no-repeat;
            background-size: auto 60px;
            text-indent: -9999px;
          }
        }
      }
    }
  }

  section#area-search {

    .inner {
      width: calc(100% - 20px);
      margin: 0 auto;
      padding-bottom: 55px;

      .title {
        padding: 10px 0 35px;
        text-align: center;

        img {
          width: 280px;
        }
      }

      ul {
        width: 100%;
        padding-bottom: 40px;
        display: flex;
        justify-content: center;
        flex-wrap: wrap;

        li {
          width: 100%;
          height: 60px;
          margin-bottom: 5px;
          border: 2px solid #333739;
          background-color: #fff;

          a {
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;

            img {
              height: 25px;
            }
          }
        }
      }
    }
  }

  section#category-search {

    .inner {
      width: calc(100% - 20px);
      margin: 0 auto;
      padding-bottom: 55px;

      .title {
        padding: 20px 0 55px;
        text-align: center;

        img {
          width: 280px;
        }
      }

      ul {
        width: 100%;
        padding-bottom: 40px;
        display: flex;
        justify-content: center;
        flex-wrap: wrap;

        li {
          width: 100px;
          margin: 0 5px 25px;
          display: flex;
          justify-content: center;
          flex-wrap: wrap;

          a {
            text-decoration: none;

            .wrap {
              height: 92px;
              width: 92px;
              border: 2px solid #333739;
              border-radius: 46px;
              display: flex;
              justify-content: center;
              align-items: center;

              img {
                height: 33px;
              }
            }

            .text-wrap {
              width: 100%;
              height: 40px;
              display: flex;
              justify-content: center;
              align-items: center;
              flex-wrap: wrap;
              flex-direction: column;

              .main-category {
                padding-top: 6px;
                color: #333739;
                font: 12px GenSenMaruGothic JP TTF;
                font-weight: bold;
              }

              .sub-category {
                color: #333739;
                font: 9px GenSenMaruGothic JP TTF;
                font-weight: bold;
              }
            }
          }
        }
      }
    }
  }

  .contact {
    background-color: rgba(184, 166, 7, 0.1);
    padding: 30px 0;

    .contact-item {
      width: 90%;
      height: 75px;
      margin: 15px auto;

      a {
        text-decoration: none;

        .item-wrap {
          width: 100%;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;

          .item-img {
            height: 25px;
          }

          .item-text {
            font: 14px GenSenMaruGothic JP TTF;
            font-weight: bold;
            line-height: 24px;
            letter-spacing: 4.32px;
          }
        }
      }
    }

    .app {
      background-color: #b8a607;

      .item-wrap {

        .item-img {
          margin-right: 20px;

          img {
            height: 100%;
          }
        }

        .item-text {
          color: #fff;
        }
      }
    }

    .cont {
      background-color: #fff;
      border: 1px solid #707070;

      .item-wrap {

        .item-img {
          margin-right: 20px;
          display: flex;
          align-items: center;

          img {
            zoom: .6;
          }
        }

        .item-text {
          color: #333739;
        }
      }
    }
  }

  .shop-list {
    text-align: center;

    .title-wrap {
      width: 90%;
      margin: auto;

      .title {
        padding: 20px 0 55px;
        text-align: center;

        img {
          width: 280px;
        }
      }
    }

    .contents-wrap {
      margin: 0 auto;
      padding: 30px 0 90px;
      width: 100%;
      background-color: rgba(255, 255, 255, 0.5);

      .contents {
        width: 90%;
        margin: 0 auto;

        ul {

          li.content-item {
            width: 100%;
            padding-bottom: 35px;

            a {
              text-decoration: none;

              .item-img {
                width: 100%;
                height: 250px;
                background-repeat: no-repeat;
                background-size: cover;
              }

              .item-text {
                text-align: left;
                margin-top: 20px;

                .item-title {
                  font: 16px GenSenMaruGothic JP TTF;
                  font-weight: bold;
                  line-height: 20px;
                  letter-spacing: 2.4px;
                  color: #b83900;
                }

                .item-address {
                  margin-top: 8px;
                  font: 12px Kozuka Gothic Pr6N;
                  line-height: 14px;
                  letter-spacing: 1.68px;
                  color: #333739;
                }

                .status {
                  margin-top: 20px;
                  display: flex;
                  justify-content: flex-start;

                  li {
                    position: relative;
                    padding-left: 22px;
                    font: 10px Kozuka Gothic Pr6N;
                    font-weight: bold;
                    line-height: 12px;
                    letter-spacing: 0.48px;
                    color: #333739;
                    margin-right: 16px;
                  }

                  .takeout:after {
                    content: '';
                    position: absolute;
                    display: block;
                    width: 17px;
                    height: 16px;
                    background: url('../../../img/icon_takeout.svg') no-repeat;
                    top: -3px;
                    left: 0;
                  }

                  .delivery:after {
                    content: '';
                    position: absolute;
                    display: block;
                    width: 18px;
                    height: 13px;
                    background: url('../../../img/icon_deri.svg') no-repeat;
                    top: 0;
                    left: 0;
                  }

                  .store:after {
                    content: '';
                    position: absolute;
                    display: block;
                    width: 21px;
                    height: 16px;
                    background: url('../../../img/icon_store.svg') no-repeat;
                    top: -3px;
                    left: 0;
                  }
                }
              }
            }
          }

          .no-found {
            width: 100%;
            text-align: center;
          }
        }

        #lazy-load {
          width: 270px;
          height: 60px;
          margin: 0 auto;
          background: #b8a607;
          border-radius: 50px;
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;

          .lazy-load-text {
            font: 13px GenSenMaruGothic JP TTF;
            font-weight: bold;
            letter-spacing: 3.6px;
            color: #fff;
          }

          .arrow {
            width: 0;
            height: 0;
            margin-left: 20px;
            border-style: solid;
            border-width: 8px 0 8px 11px;
            border-color: transparent transparent transparent #fff;
          }
        }
      }
    }
  }
}