main#top {

  section.mainvisual {
    width: 100%;
    height: 600px;
    background-image: url("../../../img/img_bg_main_2.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;

    .main-img {
      margin-top: 70px;
      max-width: 600px;
      width: 70%;

      img {
        width: 100%;
      }

    }
    &:before {
      content:"";
      display: block;
    }
  }

  .category {
    width: 1140px;
    text-align: center;
    margin: 0 auto;

    .title-wrap {
      
      img {
        width: 1140px;
      }

    }

    .content-wrap {
      margin-top: 115px;

      ul {
        width: 960px;
        margin: 0 auto;
        display: flex;
        justify-content: center;
        flex-wrap: wrap;

        li {
          width: 300px;
          height: 300px;
          margin-bottom: 35px;

          a {

            .category-item {
              width: 285px;
              height: 285px;
              margin: 7px auto;
              background: #FFFFFF;
              border-radius: 50%;
              position: relative;

              .item-img {
                position: absolute;
              }

              .item-text {
                position: absolute;
                font: 25px GenSenMaruGothic JP TTF;
                font-weight: bold;
                line-height: 21px;
                letter-spacing: 4.5px;
                color: #B83900;

                .sub-text {
                  font: 18px GenSenMaruGothic JP TTF;
                  font-weight: bold;
                  letter-spacing: 3.24px;
                }

              }

            }

          }

        }

        li:nth-child(1) {
          background-image: url("../../../img/orange.png");
          background-repeat: no-repeat;
          background-position-x: -32px;
          background-position-y: -36px;

          a {

            .category-item {

              .item-img {
                top: 57px;
                left: 85px;
              }

              .item-text {
                bottom: 48px;
                left: 66px;
              }

            }

          }

        }

        li:nth-child(2) {
          margin: 0 30px;

          a {

            .category-item {

              .item-img {
                top: 50px;
                left: 61px;
              }

              .item-text {
                bottom: 48px;
                left: 49px;
              }

            }

          }

        }

        li:nth-child(3) {
          background-image: url("../../../img/green.png");
          background-repeat: no-repeat;

          a {

            .category-item {

              .item-img {
                top: 65px;
                left: 93px;
              }

              .item-text {
                bottom: 57px;
                left: 68px;
                font: 26px GenSenMaruGothic JP TTF;
                font-weight: bold;
                line-height: 30px;
                letter-spacing: 4.68px;
              }

            }

          }

        }

        li:nth-child(4) {

          a {

            .category-item {

              .item-img {
                top: 53px;
                left: 112px;
              }

              .item-text {
                bottom: 58px;
                left: 72px;
                font: 26px GenSenMaruGothic JP TTF;
                font-weight: bold;
                line-height: 30px;
                letter-spacing: 4.68px;
              }

            }

          }

        }

        li:nth-child(5) {
          margin-left: 30px;
          background-image: url("../../../img/orange-1.png");
          background-repeat: no-repeat;
          background-position-x: -9px;
          background-position-y: -14px;

          a {

            .category-item {

              .item-img {
                top: 71px;
                left: 104px;
              }

              .item-text {
                bottom: 69px;
                left: 46px;
                font: 23px GenSenMaruGothic JP TTF;
                line-height: 26px;
                font-weight: bold;
                letter-spacing: 2.76px;
              }

            }

          }

        }

      }

      .link-area {
        margin-top: 10px;
        margin-bottom: 120px;

        a {
          text-decoration: none;

          .and-more {
            width: 460px;
            height: 90px;
            border-radius: 50px;
            background: #B8A607 0% 0% no-repeat padding-box;
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 0 auto;

            .link-text {
              display: flex;
              justify-content: center;
              align-items: center;

              .text {
                font: 20px GenSenMaruGothic JP TTF;
                line-height: 23px;
                font-weight: bold;
                letter-spacing: 3.6px;
                color: #FFFFFF;
              }

              .arrow {
                margin-left: 20px;
                width: 0;
                height: 0;
                border-style: solid;
                border-width: 7.5px 0 7.5px 11px;
                border-color: transparent transparent transparent #fff;
              }

            }

          }

        }

      }

    }

  }

  .shop-list {
    width: 1140px;
    margin: 0 auto;
    text-align: center;

    .title-wrap {
      padding: 120px 0 90px;

      img {
        width: 1140px;
      }
    }

    .content-wrap {

      .area-block {
        margin: 0 auto 50px;
        width: 1140px;
        background-color: rgba(255, 255, 255, 0.5);

        .area-title {
          padding-top: 70px;
          margin-bottom: 60px;

          img {
            width: 360px;
          }

        }

        .area-content {
          width: 1040px;
          margin: 0 auto;

          ul {
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;

            .content-item {
              width: 320px;
              margin-bottom: 75px;

              a {
                text-decoration: none;

                .item-img {
                  width: 100%;
                  height: 250px;
                  background-repeat: no-repeat;
                  background-size: cover;
                }

                .item-text {
                  text-align: left;
                  margin-top: 20px;

                  .item-title {
                    font: 20px GenSenMaruGothic JP TTF;
                    font-weight: bold;
                    line-height: 20px;
                    letter-spacing: 2.4px;
                    color: #B83900;
                  }

                  .item-address {
                    margin-top: 8px;
                    font: 14px Kozuka Gothic Pr6N;
                    line-height: 14px;
                    letter-spacing: 1.68px;
                    color: #333739;
                  }

                  .status {
                    margin-top: 20px;
                    display: flex;
                    justify-content: flex-start;

                    li {
                      position: relative;
                      padding-left: 22px;
                      font: 12px Kozuka Gothic Pr6N;
                      font-weight: bold;
                      line-height: 12px;
                      letter-spacing: 0.48px;
                      color: #333739;
                      margin-right: 16px;
                    }

                    .takeout:after {
                      content: '';
                      position: absolute;
                      display: block;
                      width: 17px;
                      height: 16px;
                      background-image: url('../../../img/icon_takeout.svg');
                      background-repeat: no-repeat;
                      top: -3px;
                      left: 0px;
                    }

                    .delivery:after {
                      content: '';
                      position: absolute;
                      display: block;
                      width: 18px;
                      height: 13px;
                      background-image: url('../../../img/icon_deri.svg');
                      background-repeat: no-repeat;
                      top: 0px;
                      left: 0px;
                    }

                    .store:after {
                      content: '';
                      position: absolute;
                      display: block;
                      width: 21px;
                      height: 16px;
                      background-image: url('../../../img/icon_store.svg');
                      background-repeat: no-repeat;
                      top: -3px;
                      left: 0px;
                    }

                  }

                }

              }

            }

          }

        }

        .link-area {
          padding: 20px 0 75px;

          a {
            text-decoration: none;

            .and-more {
              width: 460px;
              height: 90px;
              border-radius: 50px;
              background: #B8A607 0% 0% no-repeat padding-box;
              display: flex;
              align-items: center;
              justify-content: center;
              margin: 0 auto;

              .link-text {
                display: flex;
                justify-content: center;
                align-items: center;

                .text {
                  font: 20px GenSenMaruGothic JP TTF;
                  line-height: 23px;
                  font-weight: bold;
                  letter-spacing: 3.6px;
                  color: #FFFFFF;
                }

                .arrow {
                  margin-left: 20px;
                  width: 0;
                  height: 0;
                  border-style: solid;
                  border-width: 7.5px 0 7.5px 11px;
                  border-color: transparent transparent transparent #fff;
                }

              }

            }

          }

        }

      }

      .area-list {
        width: 1040px;
        margin: 40px auto 80px;

        ul {
          display: flex;
          /* justify-content: space-between; 地区が増えてきたら戻す */
          justify-content: space-around;
          flex-wrap: wrap;

          .list-item {
            width: 320px;
            height: 200px;
            margin-bottom: 30px;
            background-size: cover;
            background-repeat: no-repeat;

            a {
              text-decoration: none;

              .item-wrap {
                width: 100%;
                height: 100%;
                background-color: rgba(51, 55, 57, 0.3);

                .item-text {
                  padding-top: 80px;
                  margin: 0px auto 10px;
                  font: 24px GenSenMaruGothic JP TTF;
                  font-weight: bold;
                  line-height: 30px;
                  letter-spacing: 4.32px;
                  color: #FFFFFF;
                }

                .item-img {
                  width: 100%;
                  text-align: center;
                  margin-top: 15px;
                }

              }

            }

          }

          .list-item:nth-child(4),
          .list-item:nth-child(5),
          .list-item:nth-child(6) {

            a {

              .item-wrap {

                .item-text {
                  padding-top: 60px;
                }

              }

            }

          }

        }

      }

      .map-area {
        margin: 50px auto 0;
        width: 1140px;
        height: 570px;
      }

    }

  }

  .message {
    width: 1140px;
    margin: 0 auto;
    text-align: center;
    background: url("../../../img/img_bg_messeage.svg") no-repeat;
    background-position-x: center;
    background-position-y: 230px;

    .title-wrap {
      padding: 120px 0 90px;

      .title {

        img {
          width: 1140px;
        }

      }
    }

    .content-wrap {
      padding: 30px 0 235px;

      p {
        font: 16px Kozuka Gothic Pr6N;
        line-height: 40px;
        letter-spacing: 1.92px;
        color: #333739;

        strong {
          font: 28px GenSenMaruGothic JP TTF;
          font-weight: bold;
          line-height: 40px;
          letter-spacing: 3.36px;
          color: #B83900;
        }
      }

      p:nth-child(2) {
        margin-top: 48px;
      }

      p:nth-child(3) {
        margin-top: 35px;
      }

      p:nth-child(4) {
        margin-top: 8px;
      }

      p:last-child {
        margin-top: 40px;
        font-size: 12px;
        line-height: 30px;
        letter-spacing: 1.44px;
        color: #333739;
      }
    }

  }

  .contact {
    height: 400px;
    background-color: rgba(184, 166, 7, 0.1);
    display: flex;
    justify-content: center;
    align-items: center;

    .contact-item {
      width: 485px;
      height: 220px;

      a {
        text-decoration: none;

        .item-wrap {
          width: 100%;
          height: 100%;

          .item-img {
            text-align: center;
          }

          .item-text {
            margin-top: 25px;
            text-align: center;
            font: 24px GenSenMaruGothic JP TTF;
            font-weight: bold;
            line-height: 24px;
            letter-spacing: 4.32px;
          }

        }

      }

    }

    .app {
      background-color: #B8A607;

      .item-wrap {

        .item-img {
          margin-top: 30px;
        }

        .item-text {
          color: #FFF;
        }

      }

    }

    .cont {
      margin-left: 70px;
      background-color: #FFF;
      border: 1px solid #707070;

      .item-wrap {

        .item-img {
          margin-top: 43px;
        }

        .item-text {
          color: #333739;;
        }

      }

    }

  }

}