#shop-info {

  section.mainvisual {
      height: 310px;
      text-align: center;
      background-image: url("../../../img/img_bg_main_2.png");
      background-position-x: center;
      background-position-y: 65%;
      background-repeat: no-repeat;
      background-size: cover;    

      .main-title {
        padding-top: 155px;
        font: 38px GenSenMaruGothic JP TTF;
        font-weight: bold;
        letter-spacing: 6.84px;
        color: #333739;

        img {
          width: 262px;
        }

      }

      .sub-title {
        font: 12px GenSenMaruGothic JP TTF;
        font-weight: bold;
        letter-spacing: 2.16px;
        color: #B83900;
      }

  }

  .store-intro {
    margin: 0 0 95px;
    
    .title-wrap {
      margin-top: 90px;
      text-align: center;

      .title {
        font: 38px GenSenMaruGothic JP TTF;
        font-weight: bold;
        letter-spacing: 6.84px;
        color: #B83900;
      }

      .category {
        margin-top: 40px;
        display: flex;
        justify-content: center;

        .category-item {
          font: 16px GenSenMaruGothic JP TTF;
          font-weight: bold;
          letter-spacing: 2.88px;
          color: #333739;
          margin: 0 25px;
        }

      }

    }

    .content-wrap {
      margin-top: 60px;

      .text-area {
        width: 980px;
        margin: 0 auto;
        font: 16px Kozuka Gothic Pr6N;
        line-height: 40px;
        letter-spacing: 1.92px;
        color: #333739;
      }

      .product-area {
        width: 1135px;
        margin: 115px auto 95px;

        ul {
          display: flex;
          justify-content: center;

          li {
            width: 250px;
            margin: 0 23px;

            .item-img {
              width: 100%;
              height: 250px;
              background-repeat: no-repeat;
              background-position: center;
              background-size: cover;
            }

            .item-text {
              margin-top: 10px;

              .item-title {
                font: 20px GenSenMaruGothic JP TTF;
                font-weight: bold;
                line-height: 23px;
                letter-spacing: 1.6px;
                color: #B83900;
              }

              .item-price {
                margin-top: 5px;
                font:16px Kozuka Gothic Pr6N;
                font-weight: bold;
                letter-spacing: 1.92px;
                color: #333739;
              }

            }
            
          }

          li:first-child {
            margin: 0 23px 0 0;
          }

          li:last-child {
            margin: 0 0 0 23px;
          }

        }

      }

    }

  }

  .product-intro,
  .product-intro-sub{
    max-width: 1200px;
    background-color: rgba(255, 255, 255, 0.5);
    margin: 0 auto;
    padding-bottom: 20px;

    .title-wrap {
      text-align: center;
      padding-top: 110px;
      margin-bottom: 100px;

      .title {

        img {
          width: 100%;
          max-width: 1140px;
        }

      }

    }

    .content-wrap {

      ul {
        max-width: 1120px;
        margin: 0 auto;
        padding-bottom: 20px;
        display: flex;
        justify-content: space-between;

        .list-item {
          width: 540px;
          position: relative;

          .item-img {
            width: 100%;
            background-color: #FFF;
            padding: 10px;

            .img-wrap {
              position: relative;
              height: 300px;

              img {
                max-width: 100%;
                max-height: 100%;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
              }
  
            }

          }

          .item-title {
            margin-top: 10px;
            font-size: 22px;
            font-family: 'ヒラギノ丸ゴ ProN','Hiragino Maru Gothic ProN';
            line-height: 2rem;
            font-weight: bold;
            letter-spacing: .2rem;
            color: #B83900;
          }

          .item-text {
            margin-top: 20px;
            font:16px Kozuka Gothic Pr6N;
            line-height: 40px;
            letter-spacing: 1.92px;
            color: #333739;
          }

        }

      }

      .product-text {
        width: 980px;
        margin: 0 auto;
        padding: 10px 0;
        font-size: 16px;
        letter-spacing: 2px;
        line-height: 40px;
      }

      .contact-status {
        position: relative;
        bottom: 0;
        width: 100%;
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        margin-top: 40px;
        padding-bottom: 50px;

        li {
          width: 100%;
          margin-bottom: 20px;

          a {
            display: block;
            text-decoration: none;
            width: 365px;
            margin: 0 auto;
  
            .and-more {
              height: 70px;
              border-radius: 50px;
              display: flex;
              align-items: center;
              justify-content: center;
  
              .link-text {
                display: flex;
                justify-content: center;
                align-items: center;
  
                .text {
                  font: 20px GenSenMaruGothic JP TTF;
                  line-height: 23px;
                  font-weight: bold;
                  letter-spacing: 3.6px;
                  color: #FFFFFF;
                }
  
                .arrow {
                  margin-left: 20px;
                  width: 0;
                  height: 0;
                  border-style: solid;
                  border-width: 7.5px 0 7.5px 11px;
                  border-color: transparent transparent transparent #fff;
                }    
  
              }
  
            }

            &.pc-none {
              display: none;
            }

          }
  
        }

        .shop {

          a {

            .and-more {
              background-color: #B8A607;

              .link-text {

                .arrow {
                  margin-left: 15px;
                }

              }

            }

          }

        }

        .tel {

          a {

            .and-more {
              background-color: #333739;

              .link-text {

                .arrow {
                  margin-left: 25px;
                }

              }

            }

          }

        }
        
      }

    }

  }

  .product-intro-sub {
    margin-top: 65px;
  }

  .store-info {
    max-width: 1135px;
    margin: 60px auto 80px;
    
    .title-wrap {
      text-align: center;
      margin-bottom: 65px;
    }

    .content-wrap {
      display: flex;
      justify-content: space-between;

      .map-area {
        width: 620px;
        height: 400px;
        background: grey;
      }

      .info-area {
        margin-top: -10px;
        padding-left: 20px;
        width: 460px;
        
        .store-name {
          font: Bold 32px GenSenMaruGothic JP TTF;
          line-height: 50px;
          letter-spacing: 3.84px;
          color: #B83900;
          border-bottom: solid #B83900 2px;
        }

        .info-list {

          margin: 34px 0;

          .list-item {
            display: flex;
            justify-content: flex-start;
            align-items: center;

            .list-title {
              width: 75px;
              margin-right: 30px;
              font: 16px Kozuka Gothic Pr6N;
              font-weight: bold;
              line-height: 40px;
              letter-spacing: 1.92px;
              color: #333739;
            }

            .list-content {
              width: 330px;
              font: 14px Kozuka Gothic Pr6N;
              letter-spacing: 1.92px;
              color: #333739;
            }

          }

        }

        .link-area {

          a {
            text-decoration: none;
  
            .and-more {
              width: 460px;
              height: 90px;
              border-radius: 50px;
              display: flex;
              align-items: center;
              justify-content: center;
              margin: 0 auto;
              background-color: #B8A607;
  
              .link-text {
                display: flex;
                justify-content: center;
                align-items: center;
  
                .text {
                  font: 20px GenSenMaruGothic JP TTF;
                  line-height: 23px;
                  font-weight: bold;
                  letter-spacing: 3.6px;
                  color: #FFFFFF;
                }
  
                .arrow {
                  margin-left: 20px;
                  width: 0;
                  height: 0;
                  border-style: solid;
                  border-width: 7.5px 0 7.5px 11px;
                  border-color: transparent transparent transparent #fff;
                }    
  
              }
  
            }
  
          }

        }

      }

    }

  }

  .more-shop {
    width: 100%;
    background-color: rgba(184, 166, 7, 0.1);
    padding-bottom: 60px;

    .title-wrap {
      text-align: center;
      padding: 80px 0;

      .title {
        
        img {
          width: 545px;
        }

      }

    }

    .content-wrap {
      width: 100%;
      max-width: 1200px;
      margin: auto;

      ul {
        display: flex;
        justify-content: space-around;
        flex-wrap: wrap;

        .list-item {
          width: 250px;
          margin: 0 23px 40px;

          a {
            text-decoration: none;

            .item-img {
              width: 250px;
              height: 250px;
              background-repeat: no-repeat;
              background-size: cover;
              background-position: center;
            }

            .item-text {
              margin-top: 10px;

              .item-title {
                font: 20px GenSenMaruGothic JP TTF;
                font-weight: bold;
                line-height: 23px;
                letter-spacing: 2.4px;
                color: #B83900;
              }

            }

          }

        }

      }

      .link-area {
        //margin-top: 100px;
        //padding-bottom: 85px;

        a {
          text-decoration: none;

          .and-more {
            width: 460px;
            height: 90px;
            border-radius: 50px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 0 auto;
            background-color: #B8A607;

            .link-text {
              display: flex;
              justify-content: center;
              align-items: center;

              .text {
                font: 20px GenSenMaruGothic JP TTF;
                line-height: 23px;
                font-weight: bold;
                letter-spacing: 3.6px;
                color: #FFFFFF;
              }

              .arrow {
                margin-left: 20px;
                width: 0;
                height: 0;
                border-style: solid;
                border-width: 7.5px 0 7.5px 11px;
                border-color: transparent transparent transparent #fff;
              }    

            }

          }

        }

      }

    }

  }

  .shop-search {
    padding-top: 90px;
    margin-bottom: 100px;

    .title-wrap {
      text-align: center;

      .title {

        img {
          max-width: 1135px;
        }

      }

    }

    .content-wrap {
      margin-top: 125px;

      .title-wrap {
        text-align: center;
        margin-bottom: 55px;
      }

      .area-search {

        .title-wrap {

          .title {

            img{
              width: 435px;
            }

          }

        }

        ul {
          display: flex;
          justify-content: space-between;
          flex-wrap: wrap;
          width: 1140px;
          margin: 0 auto;
  
          .list-item {
            width: 370px;
            height: 120px;
            border: 2px solid #333739;
            background-color: #FFF;
            margin-bottom: 15px;
            text-align: center;
  
            a {
              text-decoration: none;
  
              .item-wrap {
                width: 100%;
                height: 100%;
  
                .item-text {
                  padding-top: 40px;
                  margin: 0px auto 5px;
                  font: 24px GenSenMaruGothic JP TTF;
                  font-weight: bold;
                  line-height: 30px;
                  letter-spacing: 4.32px;
                  color: #333739;

                  img {
                    width: 138px;
                  }

                }
  
                .font-min {
                  font: 22px GenSenMaruGothic JP TTF;
                  font-weight: bold;
                  letter-spacing: 2.2px;
                }
  
                .item-img {
                  width: 100%;
                  text-align: center;
                }
                
              }
  
            }
  
          }
  
          .big-item {
            width: 563px;
          }
  
        }
  
      }

      .category-search {
        margin-top: 170px;
        
        .title-wrap {
          margin-bottom: 55px;

          .title {

            img {
              width: 435px;
            }

          }

        }

        .content-wrap {
          margin-top: 0px;

          ul {
            display: flex;
            justify-content: center;

            .list-item {
              width: 165px;
              margin: 0 23px;

              .circle {
                width: 165px;
                height: 165px;
                background-color: #FFF;
                border: 2px solid #333739;
                border-radius: 50%;
                display: flex;
                justify-content: center;
                align-items: center;
              }

              .item-text {
                margin-top: 20px;
                text-align: center;
                font: 18px GenSenMaruGothic JP TTF;
                line-height: 20px;
                font-weight: bold;
                letter-spacing: 2.6px;
                color: #333739;

                .sub-text {
                  margin-top: 10px;
                  font: 16px GenSenMaruGothic JP TTF;
                  line-height: 20px;
                  letter-spacing: 2.88px;
                }

              }

            }
          }

        }

      }

    }

  }

  .contact {
    height: 400px;
    background-color: rgba(184, 166, 7, 0.1);
    display: flex;
    justify-content: center;
    align-items: center;

    .contact-item {
      width: 485px;
      height: 220px;

      a {
        text-decoration: none;

        .item-wrap {
          width: 100%;
          height: 100%;
  
          .item-img {
            text-align: center;
          }
  
          .item-text {
            margin-top: 25px;
            text-align: center;
            font: 24px GenSenMaruGothic JP TTF;
            font-weight: bold;
            line-height: 24px;
            letter-spacing: 4.32px;
          }
  
        }  

      }

    }

    .app {
      background-color: #B8A607;

      .item-wrap {

        .item-img {
          margin-top: 30px;
        }

        .item-text {
          color: #FFF;
        }

      }

    }

    .cont {
      margin-left: 70px;
      background-color: #FFF;
      border: 1px solid #707070;

      .item-wrap {

        .item-img {
          margin-top: 43px;
        }

        .item-text {
          color: #333739;;
        }

      }

    }

  }

}

/* 企業情報入力時追加CSS */

#shop-info {

  .store-info .info-area .info-list .list-content a {
    color: #333739;
  }

  .map-area {
    position: relative;
    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }

}