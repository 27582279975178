#form {

  section.mainvisual {
    height: 102px;
    text-align: center;
    background-image: url("../../../img/img_bg_main_2.png");
    background-position-x: center;
    background-position-y: 65%;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    flex-wrap: wrap;

    .main-title {
      font-size: 23px;
      font-family: 'ヒラギノ丸ゴ ProN','Hiragino Maru Gothic ProN';
      font-weight: bold;
    //line-height: 1.8rem;
      color: #333739;
      width: 100%;

      img {
        height: 30px;
      }

    }

    .sub-title {
      width: 100%;
      font-size: 12px;
      font-family: 'ヒラギノ丸ゴ ProN','Hiragino Maru Gothic ProN';
      font-weight: bold;
      color: #B83900;
    }

  }

  section.form {

      .inner {
        padding: 35px 0 170px;
        margin: 0 auto;
        width: calc(100% - 20px);

        .title-wrap {
          background-color: rgba(255, 255, 255, 0.5);
          text-align: center;
          margin: 0 auto;
          padding: 30px 0 25px;

          .title {
            font: 18px GenSenMaruGothic JP TTF;
            color: #B83900;
            font-weight: bold;
          }
          
        }

        .content-wrap {

          form {

            .main-area {
              padding-bottom: 60px;
            }

            .sub-area {
              margin-top: 50px;
              padding-bottom: 60px;

              .title-wrap {
                background: none;

                .sub-text {
                  display: block;
                  margin-top: 15px;
                }

              }

            }

            .main-area,
            .sub-area{
              background-color: rgba(255, 255, 255, 0.5);

              p {
                width: calc(100% - 30px);
                margin: 0 auto 15px;
                position: relative;

                #zip-btn {
                  width: 85px;
                  height: 26px;
                  margin-left: 15px;
                  background-color: #333739;
                  color: #fff;
                  font: 14px GenSenMaruGothic JP TTF;
                  font-weight: bold;
                  display: inline-flex;
                  justify-content: center;
                  align-items: center;
                  border-radius: 5px;

                  .arrow {
                    margin-left: 6px;
                    width: 0;
                    height: 0;
                    border-style: solid;
                    border-width: 6px 0 6px 8px;
                    border-color: transparent transparent transparent #fff;
                  }

                }

                .mark {
                  margin-right: 7px;
                }

                .time-text {
                  margin: 0 10px;
                }


              }

              p:nth-child(2),
              p:nth-child(3),
              .text-210 {
                margin-bottom: 8px;
              }

              .textarea-wrap {
                align-items: flex-start;
              }

              .pay-method-top {
                padding-top:15px;
                margin-bottom: 5px;
              }

              .pay-method-wrap {
                align-items: flex-start;
                margin-bottom: 5px;

                .checkbox-wrap {
                  .wpcf7-list-item-label {
                    margin-right: 5px;
                    font: 12px GenSenMaruGothic JP TTF;
                    font-weight: bold;
                  }

                  .wpcf7-list-item:nth-child(1),
                  .wpcf7-list-item:nth-child(2) {
                    display: inline-block;
                    margin-bottom: 5px;
                  }

                  .wpcf7-list-item {
                    margin-right: 0;
                    margin-left: 0;
                  }

                  .wpcf7-list-item:nth-child(3) {
                    display: inline-block;
                    margin-right: calc(100% - 205px);
                    margin-bottom: 5px;
                  }
                }

              }

              input[type="text"],
              input[type="tel"],
              input[type="email"] {
                width: 100%;
                height: 36px;
                border: 1px solid #707070;
              }

              select {
                width: 110px;
                height: 36px;
                border: 1px solid #707070;
              }

              input[type="checkbox"] {
                width: 20px;
                height: 20px;
                appearance: none;
                vertical-align: middle;
                border: 1px solid #707070;
                border-radius: 3px;
                background-color: #fff;
                margin-bottom: 5px;
              }

              .postal-code {
                display: inline-block;
                width: calc(100% - 130px) !important;
              }

              .text-630 {
                width: calc(100% - 40px) !important;
              }

              .text-595 {
                width: calc(100% - 90px) !important;
              }

              textarea {
                width: 100%;
                height: 140px;
                border: 1px solid #707070;
              }

              .sp-textarea {
                height: 70px;
              }

              .holiday-wrap {
                .wpcf7-list-item-label {
                  font: 14px GenSenMaruGothic JP TTF;
                  font-weight: bold;
                  color: #333739;
                  margin: 0 11px 5px 5px;
                }
                .wpcf7-list-item {
                  margin-right: 0;
                  margin-left: 0;
                }
                .wpcf7-list-item:nth-child(4) {
                  margin-right: calc(100% - 245px);
                }
              }

              select {/* selectのスタイルを無効化 */
                -webkit-appearance: none;
                -moz-appearance: none;
                appearance: none;
                border-radius: unset;
                background-color: #fff;
                border: 1px solid #707070;
              }

              .time-wrap {
                display: flex;
                align-items: center;
              }

              .select-wrap {
                position: relative;
                display: inline-block;
              }

              .select-wrap:after {
                content: '';
                position: absolute;
                width: 0;
                height: 0;
                border-style: solid;
                border-width: 8px 5px 0 5px;
                border-color: #333739 transparent transparent transparent;
                top: 16px;
                right: 10px;
              }


              .label-195 {
                width: 100%;
                display: inline-flex;
                justify-content: flex-start;
                align-items: center;
                color: #333739;
                font: 14px GenSenMaruGothic JP TTF;
                font-weight: bold;
                margin-bottom: 5px;
              }

              .mark-label {
                margin-right: 12px;
              }

              .holiday-label {
                margin-top: 15px;
              }

              .text-left {
                justify-content: flex-end;
              }

              span.required {
                font-size: 11px;
                width: 36px;
                height: 20px;
                display: flex;
                justify-content: center;
                align-items: center;
                padding: 0;
                color: #fff;
                border-radius: 3px;
                background-color: #D04829;
                border-radius: 14px;
                margin-left: 12px;
              }

              .input-required {
                margin: 0 16px 0 50px;
              }

              .note {
                font: 14px GenSenMaruGothic JP TTF;
                font-weight: bold;
                color: #333739;
                margin-right: 5px;
              }

              .checkbox-wrap {
                display: block;
                margin-top: 10px;
                margin-left: 20px;
                width: 233px;
              }

              .sns-name {
                width: 115px;
                font: 16px GenSenMaruGothic JP TTF;
                font-weight: bold;
                color: #333739;
              }

            }

            .item-label {
              margin-left: 5px;
            }

            .method-name {
              margin-left: 5px;
            }

            .method-name:nth-of-type(1) {
              margin-right: 50px;
              vertical-align: top;
            }

            .method-name:nth-of-type(2) {
              margin-right: 32px;
            }

            .method-name:nth-of-type(3) {
              margin-right: 65px;
            }

            .method-name.last {
              margin: 0 10px 0 5px;
              vertical-align: middle;
            }

            .image-method-wrap {

              .wpcf7-list-item {
                display: inline-block;
              }

              .wpcf7-list-item:nth-child(1) {
                margin-right: 50px;
                margin-bottom: 15px;

              }

              .wpcf7-list-item:nth-child(2) {
                margin-right: calc(100% - 200px);
                margin-bottom: 15px;
              }

              .wpcf7-list-item:nth-child(3) {
                margin-right: 65px;
              }

            }

            .image-method-other {
              margin-left: 10px;
            }

            .image-method-note {
              font-size: 12px;

              span.red {
                display: inline-block;
                margin: 5px 0 0 12px;
                color: #D04829;
                border-bottom: 1px solid #D04829;
              }

            }

            .sns-wrap {
              padding-top: 10px;
            }

            .submit-wrap {
              text-align: center;
              margin-top: 60px;

              .checklist {
                font: 12px GenSenMaruGothic JP TTF;
                line-height: 150%;
                width: 90%;
                margin: 0 auto;
                padding-bottom: 40px;
                text-align: left;

                span {
                  font: 14px GenSenMaruGothic JP TTF;
                  font-weight: bold;
                }
              }

              input[type="checkbox"] {
                width: 20px;
                height: 20px;
                appearance: none;
                vertical-align: middle;
                border: 1px solid #707070;
                border-radius: 3px;
                background-color: #fff;
              }

              .concent-note {
                display: inline-block;
                font: 12px GenSenMaruGothic JP TTF;
                font-weight: bold;
                color: #333739;
                margin-left: 5px;
              }

              .confirm-wrap {
                width: 270px;
                margin: 20px auto 0;
                position: relative;

                input[type="submit"] {
                  width: 270px;
                  height: 60px;
                  border-radius: 50px;
                  display: inline-flex;
                  align-items: center;
                  justify-content: center;
                  font: 20px GenSenMaruGothic JP TTF;
                  font-weight: bold;
                  color: #fff;
                  background-color: #B8A607;
                }

                span.arrow {
                  position: absolute;
                  margin-left: 20px;
                  width: 0;
                  height: 0;
                  border-style: solid;
                  border-width: 7.5px 0 7.5px 11px;
                  border-color: transparent transparent transparent #fff;
                  top: 23px;
                  right: 70px;
                }

              }


            }

          }

        }

      }

  }

}
